
import { TResetPasswordSchema, resetPasswordSchema } from "../Types";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { ax } from "../Utils";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import { Container, ContentContainer, Logo, LogoImg, Nav, NavBar, NavItem, PageBackground, ScreenProtector } from "../Styling/NavBar-styling";
import { MainContent } from "../LoginPage/LoginPage-styling";
import { AuthorizationContainer, AuthorizationTitle, AuthorizationText, AuthorizationForm, AuthorizationInput, AuthorizationError, AuthorizationButton, AuthorizationLabel } from "./Authorization-styling";
import Swal from "sweetalert2";

const logoLink = "https://pngimg.com/uploads/letter_c/letter_c_PNG97.png";

export default function ResetPasswordPage() {
  //const { email, token } = useParams(); // Using useParams to get the token from the URL path
  const navigate = useNavigate();

  const [params, setParams] = useSearchParams();
  const email = params.get('email');
  const token = params.get('token');

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
    clearErrors,
  } = useForm<TResetPasswordSchema>({
    resolver: zodResolver(resetPasswordSchema),
  });

  const onSubmit = async (data: TResetPasswordSchema) => {
    console.log("submitting form", data);
    const requestBody = {
      token: token,
      password: data.password,
    };

    try {
      await ax.post('/auth/reset-password', requestBody);
    }
    catch (error) {
      Swal.fire({
        title: 'Ett problem uppstod när vi skulle byta lösenordet!',
        text: 'Försök igen senare.',
        icon: 'error',
      });
      return;
    }

    toast.success("Lösenordet har ändrats!");
    reset();

    navigate('/login');
  };

  const handleHome = async () => {
    navigate('/login');
  };
  const handleBack = async () => {
    navigate('/login');
  };

  if (!token) {
    return <h1>No token provided!</h1>;
  }

  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <LogoImg onClick={handleHome} src={logoLink} alt="Image"/>
            <Logo>ragge.ai</Logo>
            <Nav>
              <NavItem onClick={handleBack}>Tillbaka</NavItem>
            </Nav>
          </NavBar>
          <ContentContainer>
            <MainContent>
              <AuthorizationContainer>
                <AuthorizationTitle>Återställ lösenord</AuthorizationTitle>
                  <AuthorizationText>
                    Ange ett nytt lösenord för ditt konto {email}.
                  </AuthorizationText>
                <AuthorizationForm onSubmit={handleSubmit(onSubmit)}>
                  <AuthorizationLabel htmlFor="password">Nytt lösenord</AuthorizationLabel>
                  <AuthorizationInput
                    {...register("password")}
                    type="password"
                    placeholder="lösenord"
                  />
                  {errors.password && (
                    <AuthorizationError>{`${errors.password.message}`}</AuthorizationError>
                  )}

                  <AuthorizationLabel htmlFor="confirmPassword">Upprepa lösenord</AuthorizationLabel>
                  <AuthorizationInput
                    {...register("confirmPassword")}
                    type="password"
                    placeholder="lösenord (igen)"
                  />
                  {errors.confirmPassword && (
                    <AuthorizationError>{`${errors.confirmPassword.message}`}</AuthorizationError>
                  )}
                  <AuthorizationButton
                    disabled={isSubmitting}
                    type="submit"
                  >
                    Spara
                  </AuthorizationButton>
                </AuthorizationForm>
              </AuthorizationContainer>
            </MainContent>
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
    
  );
}