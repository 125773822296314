import styled from 'styled-components';

export const AdminMainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  width: 100%;
`;

export const AdminCategoryContainer = styled.div`
  display: flex;
  max-width: 1440px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.5);
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25),
              inset 0 0 40px 15px rgba(0, 0, 0, 0.07);
  margin-bottom: 9px;
`;

export const AdminCategoryTitle = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 0 10px 0;
  color: #fff;
`;

export const SmallTextSpan = styled.span`
  font-size: 1rem;
  color: #fff;
  font-weight: 400;
`;

export const AdminSettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: safe start;
  margin-bottom: 10px;
  width: 100%;
`;

export const AdminSettingText = styled.p`
  font-size: 1.2rem;
  color: #fff;
  text-align: start;
  margin-bottom: 5px;
  margin-top: 0.2rem;
`;

export const AdminSettingSelectBox = styled.select`
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  /* max-width: 25rem; */
  padding: 8px 20px 8px 8px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

export const AdminSettingInput = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  /* max-width: 25rem; */
  padding: 8px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
`;

export const AdminSettingTextarea = styled.textarea`
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  width: 100%;
  padding: 8px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  font-size: 1rem;
  color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  resize: none;
`;

export const AdminCheckbox = styled.input.attrs({type: 'checkbox'})`
  appearance: none;  
  width: 25px;
  height: 25px;
  margin: 0 10px;
  border: 2px solid #d8d8d8;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background-color 0.5s, border-color 0.5s;
  align-self: safe center;

  &:hover {
      border-color: #888;
  }
  &:checked {
      background-color: #0f86a1;
      border-color: #000;
  }
  &:checked:before {
    content: '\\2713';
    display: block;
    font-size: var(--check-size, 26px);
    font-weight: 500;
    color: #fff;
    line-height: 0.75;
    text-align: center;
  }
  &:checked:hover {
    border-color: #888;
  }
`;

export const AdminSettingForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 25rem;
`;

export const AdminListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  width: 100%;
  /* max-width: 25rem; */
  max-height: 15rem;
  overflow: auto;
  padding: 10px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
  box-shadow: inset 0 0 10px 3px rgba(0, 0, 0, 0.15);
`;

export const AdminListItem = styled.div`
  margin-top: 5px;

  &:first-child {
    margin-top: 0;
  }
`;

export const DeleteButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: #d0d0d0;
    width: 16px;
    height: 16px;
    //margin-right: 10px;

    &:hover {
        color: #ff0000;
    }

    &:active {
        color: #ee0000;
    }
`;

export const ListConfigureButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 0.6rem;
  color: #d0d0d0;
  width: 16px;
  height: 16px;
  
  &:hover {
    color: #999;
  }

  &:active {
    color: #808080;
  }
`;

export const AdminButton = styled.button`
  width: auto;
  align-self: flex-start;
  background-color: #0f86a1;
  color: #fff;
  border: 1px solid #333;
  border-radius: 5px;
  margin-top: 0.7rem;
  font-size: 1rem;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #0c6d85;
  }
  &:active {
    background-color: #0c6d85;
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  }
`;

export const RedAdminButton = styled(AdminButton)`
  background-color: #a12a0f;
  &:hover {
    background-color: #80200c;
  }
  &:active {
    background-color: #80200c;
    /* box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4); */
  }
`;

export const GrayAdminButton = styled(AdminButton)`
  background-color: #e5e5e5;
  color: #000;
  align-self: center;
  &:hover {
    background-color: #d0d0d0;
  }
  &:active {
    background-color: #d0d0d0;
  }
`;

export const ShowAdvancedOptionsButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #f0f0f0;
  
  &:hover {
    color: #ccc;
  }
`;

export const AssistantConfigPopupWindow = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 90%;
  overflow-y: auto;
`;

export const PopupForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 60rem;
`;

export const PopupFormLabel = styled.label`
  font-size: 1.1rem;
  margin-bottom: 0.2rem;
`;

export const PopupFormInput = styled.input`
  border: 1px solid #333;
  border-radius: 5px;
  padding: 0.5rem;
`;

export const PopupFormTextarea = styled.textarea`
  border: 1px solid #333;
  border-radius: 5px;
  padding: 0.5rem;
  resize: none;
`;

export const PopupFormSelect = styled.select`
  border: 1px solid #333;
  border-radius: 5px;
  padding: 0.5rem;
`;

export const PopupFormButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: row;
`;

export const PopupFormCancelButton = styled.button`
  background-color: #fff;
  color: #0f86a1;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #d8d8d8;
  }
  &:active {
    background-color: #d8d8d8;
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  }
`;

export const PopupFormSubmitButton = styled.button`
  background-color: #0f86a1;
  color: #fff;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #0c6d85;
  }
  &:active {
    background-color: #0c6d85;
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  }
`;

export const AdminFormTitle = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 1.5rem 0 0.5rem 0;
  color: #fff;
`;

export const AdminPopupWindow = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const AdminPopupText = styled.p`
  font-size: 20px;
  color: #000;
  text-align: start;
  margin-bottom: 5px;
  margin-top: 1rem;
`;

export const AdminPopupSelectBox = styled.select`
  border: 1px solid #333;
  border-radius: 6px;
  max-width: 25rem;
  padding: 8px 20px 8px 8px;
  outline: none;
  background-color: #fafafa;
  box-sizing: border-box;
  font-size: 16px;
  color: #000;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

export const AdminPopupInput = styled.input`
  border: 1px solid #333;
  border-radius: 6px;
  max-width: 25rem;
  padding: 8px;
  outline: none;
  background-color: #fafafa;
  box-sizing: border-box;
  font-size: 1rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

  &:focus {
    border-color: #0f86a1;
    background-color: #fcfcfc;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2),
                inset 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
  }
`;

export const AdminPopupCheckbox = styled.input.attrs({type: 'checkbox'})`
  && {
    width: 25px;
    height: 25px;
    margin: 0 10px;
    border: 3px solid #333;
    background-color: #fafafa;
    line-height: 0.75;
    font-size: 26px;

    &:hover {
        border-color: #666;
    }
    &:checked:hover {
      border-color: #666;
    }
  }
`;

export const AdminPopupCheckboxReadOnly = styled.input.attrs({type: 'checkbox'})`
  && {
    cursor: not-allowed;
    background-color: #777;
    border-color: #444;
    width: 25px;
    height: 25px;
    margin: 0 10px;
    border: 3px solid #444;
    line-height: 0.75;
    font-size: 26px;

    &:checked {
      background-color: #777;
      border-color: #444;
    }
  }
`;

export const AdminPopupPermissionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-evenly;

`;

export const AdminPopupCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AdminPopupCheckboxLabel = styled.label`
  font-size: 1rem;
  margin-bottom: 0.2rem;
`;

export const ConfigSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  border: 1px solid #333;
  margin-top: 1.2rem;
`;

export const ConfigSectionTitle = styled.h2`
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0 0 0.3rem 0;
  color: #fff;
`;

export const AdminInputError = styled.p`
  color: #a12a0f;
  margin-bottom: 0;
  font-weight: 500;
`;