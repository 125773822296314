import { useForm } from "react-hook-form";
import { AdminButton, AdminSettingContainer, AdminSettingText, AdminSettingTextarea, ConfigSectionContainer, ConfigSectionTitle } from "../../AdminPage-styling";
import { chatbotBehaviorSchema, TChatbotBehaviorSchema } from "../../../Types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useChatbotAssistantConfig } from "../../../Queries/useChatbotAssistantConfig";
import { useSelectedChatbot } from "../../../Queries/useSelectedChatbot";
import { toast } from "react-toastify";
import { useAssistantConfigMutation } from "../../../Mutations/AssistantConfigMutation";
import Swal from "sweetalert2";
import { useChatbotConfigMutation } from "../../../Mutations/ChatbotConfigMutation";



const BehaviorConfig = () => {

  const {selectedChatbot, updateSelectedChatbot} = useSelectedChatbot();
  const {data: assistantConfig} = useChatbotAssistantConfig(selectedChatbot?.assistant_config_id);
  
  const assistantConfigMutation = useAssistantConfigMutation();
  const chatbotConfigMutation = useChatbotConfigMutation();
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    reset,
  } = useForm<TChatbotBehaviorSchema>({
    resolver: zodResolver(chatbotBehaviorSchema),
  });

  useEffect(() => {
    if (!selectedChatbot && !isDirty) {
      reset({
        background: '',
        goal: '',
        tonality: '',
        scope: '',
        purpose: '',
      });
    } else if (assistantConfig && !isDirty) {
      reset({
        background: assistantConfig.background,
        goal: assistantConfig.goal,
        tonality: assistantConfig.tonality,
        scope: assistantConfig.scope,
        purpose: assistantConfig.purpose,
      });
    }
  }, [assistantConfig, reset, isDirty, selectedChatbot]);

  useEffect(() => {
    if (selectedChatbot?.assistant_config_id) {
      reset({
        background: assistantConfig?.background,
        goal: assistantConfig?.goal,
        tonality: assistantConfig?.tonality,
        scope: assistantConfig?.scope,
        purpose: assistantConfig?.purpose,
      });
    }
    else {
      reset({
        background: '',
        goal: '',
        tonality: '',
        scope: '',
        purpose: '',
      });
    }
  }, [selectedChatbot?.assistant_config_id]);

  const onSubmit = async (data: TChatbotBehaviorSchema) => {
    console.log(data);
    if (assistantConfig?.id) {
      const requestBody = {
        id: assistantConfig.id,
        background: data.background,
        goal: data.goal,
        tonality: data.tonality,
        scope: data.scope,
        purpose: data.purpose,
        for_external_chatbot: true,
      }
      try {
        assistantConfigMutation.mutate(requestBody, {
          onSuccess: (response) => {
          toast.success('Beteende har sparats');
          }
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Något gick fel',
          text: 'Försök igen senare',
        });
      }
    }
    else {
      const requestBody = {
        background: data.background,
        goal: data.goal,
        tonality: data.tonality,
        scope: data.scope,
        purpose: data.purpose,
        for_external_chatbot: true,
      }
      try {
        assistantConfigMutation.mutate(requestBody, {
          onSuccess: (response) => {
            const requestBody2 = {
              assistant_config_id: response.data.id,
            }
            chatbotConfigMutation.mutate(requestBody2, {
              onSuccess: (response2) => {
                updateSelectedChatbot(response2.data);
                toast.success('Beteende har sparats');
              }
            });
          }
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Något gick fel',
          text: 'Försök igen senare',
        });
      }
    }
  }

  return (
    <ConfigSectionContainer>
        <ConfigSectionTitle>Beteende</ConfigSectionTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AdminSettingContainer>
            <AdminSettingText>Bakgrund</AdminSettingText>
            <AdminSettingTextarea
              placeholder="Bakgrund"
              rows={3}
              {...register("background")}
            />
          </AdminSettingContainer>
          <AdminSettingContainer>
            <AdminSettingText>Mål</AdminSettingText>
            <AdminSettingTextarea
              placeholder="Mål"
              rows={3}
              {...register("goal")}
            />
          </AdminSettingContainer>
          <AdminSettingContainer>
            <AdminSettingText>Syfte</AdminSettingText>
            <AdminSettingTextarea
              placeholder="Syfte"
              rows={3}
              {...register("purpose")}
            />
          </AdminSettingContainer>
          <AdminSettingContainer>
            <AdminSettingText>Tonalitet</AdminSettingText>
            <AdminSettingTextarea
              placeholder="Tonalitet"
              rows={3}
              {...register("tonality")}
            />
          </AdminSettingContainer>
          <AdminSettingContainer>
            <AdminSettingText>Omfattning</AdminSettingText>
            <AdminSettingTextarea
              placeholder="Omfattning"
              rows={3}
              {...register("scope")}
            />
          </AdminSettingContainer>
          <AdminButton 
            type="submit"
            disabled={isSubmitting}
          >
            Spara
          </AdminButton>
        </form>
      </ConfigSectionContainer>
  )
}

export default BehaviorConfig;