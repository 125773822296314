import { useQuery } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";


export const useShowChatbotConfig = () => {
  const queryClient = useQueryClient();

  const {data: showChatbotConfig} = useQuery<boolean, Error>({
      queryKey: ['chatbot_config', 'show'],
      queryFn: () => Promise.resolve(false),
      enabled: false,
      initialData: false,
  });

  const updateShowChatbotConfig = (show: boolean) => {
    queryClient.setQueryData(['chatbot_config', 'show'], show);
  }

  return {showChatbotConfig, updateShowChatbotConfig};
}