import { AdminCategoryTitle, AdminSettingContainer, AdminSettingText } from "../../AdminPage-styling";
import AddInbox from "./AddInbox";
import EmailInboxList from "./EmailInboxList";


const AdminFeedbackTab = () => {





  return (
    <>
      <AdminCategoryTitle>E-postinkorgar</AdminCategoryTitle>
      <EmailInboxList />
      <AddInbox />
    </>
  );
};

export default AdminFeedbackTab;
