import { useQuery, useQueryClient } from "@tanstack/react-query"
import { AssistantConfig } from "../Types";


export const useSelectedAssistant = () => {
  const queryClient = useQueryClient();

  const  {data: selectedAssistant } = useQuery<AssistantConfig | null>({
    queryKey: ['selected', 'assistant'],
    queryFn: () => Promise.resolve(null),
    enabled: false,
    initialData: null,
  });

  const updateSelectedAssistant = (assistant: AssistantConfig | null) => {
    queryClient.setQueryData<AssistantConfig | null>(['selected', 'assistant'], assistant);
  };

  return { selectedAssistant, updateSelectedAssistant };
}