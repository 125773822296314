// MarkdownRenderer.tsx
import React from 'react';
import ReactMarkdown, {Components} from 'react-markdown';
import { BlinkingSpan, CopilotChatItemText } from '../UserPage/UserPage-styling';
import styled from 'styled-components';

interface ExternalMarkdownProps {
  markdownText: string;
  fontSize?: number;
  textColor?: string;
}

const MarkdownContainer = styled.div`
  color: #fff;

  line-height: 1.25;
  padding: 0;
  margin: -1rem 0;
`;

const ExternalMarkdown = (mrprops: ExternalMarkdownProps) => {
  const hasContent = mrprops.markdownText.trim().length > 0;

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const url = e.currentTarget.href;
    window.postMessage({ type: 'OPEN_LINK', url }, '*');
  };

  return (
    <MarkdownContainer style={{color: mrprops.textColor}} >
      <ReactMarkdown
        components={{
          a: ({ node, children, href, ...props }) => (
            <a 
              href={href} 
              onClick={handleLinkClick}
              {...props}
            >
              {children}
            </a>
          )
        }}
      >
        {mrprops.markdownText}
      </ReactMarkdown>
    </MarkdownContainer>
  );
};

export default ExternalMarkdown;