import { useQuery, useQueryClient } from "@tanstack/react-query"
import { ChatbotSettings } from "../Types"


export const useChatbotSettings = () => {
  const queryClient = useQueryClient();
  console.log('useChatbotSettings');
  const {data: chatbotSettings} = useQuery<ChatbotSettings | null, Error>({
    queryKey: ['chatbot_settings'],
    queryFn: () => Promise.resolve(null),
    enabled: false,
    initialData: null,
    //notifyOnChangeProps: ['data'], // Ensure updates trigger re-renders
  });

  const updateChatbotSettings = (settings: ChatbotSettings | null) => {
    queryClient.setQueryData<ChatbotSettings | null>(['chatbot_settings'], settings);
  };

  return { chatbotSettings, updateChatbotSettings };
};