import { FaTrashCan, FaWrench } from "react-icons/fa6";
import { Inbox } from "../../../Types";
import { AdminListContainer, AdminListItem, DeleteButton, ListConfigureButton } from "../../AdminPage-styling";
import { useFeedbackSourceList } from "../../../Queries/useFeedbackSourceList";
import Swal from "sweetalert2";
import { FaSync } from "react-icons/fa";
import { ax, formatDate } from "../../../Utils";
import { useQueryClient } from "@tanstack/react-query";
import '../../../CSS/swal.css';


const EmailInboxList = () => {

  const queryClient = useQueryClient();

  const { data: feedbackSourceList } = useFeedbackSourceList('email');


  const handleDeleteInboxPopup = (inboxId: number, inboxName: string) => {
    console.log(feedbackSourceList);
    Swal.fire({
      title: `Är du säker på att du vill ta bort inkorgen ${inboxName}?`,
      text: "Du kan inte ångra beslutet!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#888",
      confirmButtonText: "Ta bort inkorg",
      cancelButtonText: "Avbryt"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteInbox(inboxId);
      }
    });
  };

  const deleteInbox = async (inboxId: number) => {
    try {
      await ax.delete(`/feedback_source/${inboxId}`)
        .then(() => {
          queryClient.setQueryData(['feedback_source', 'all', 'list', { feedback_source_type_name: 'email' }], (oldData: Inbox[]) => {
            return oldData.filter((inbox) => inbox.id !== inboxId);
          });
          Swal.fire({
            title: "Inkorgen borttagen!",
            icon: "success"
          });
        });
    } catch (error) {
      Swal.fire({
        title: "Borttagning misslyckades!",
        text: "Försök igen senare",
        icon: "error"
      });
    }
  };

  const handleConfigureInbox = (inbox: Inbox) => {
    Swal.fire({
      title: "Konfigurera inkorg",
      input: 'text',
      inputLabel: 'Namnge inkorg',
      inputValue: inbox.name,
      inputPlaceholder: 'Namn',
      showCancelButton: true,
      confirmButtonText: "Uppdatera inkorg",
      cancelButtonText: "Avbryt",
      customClass: {
        inputLabel: 'swal2-input-label-custom'
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        updateInbox(inbox, result.value);
      }
      else if (!result.value) {
        Swal.fire({
          title: "Inkorgen behöver ett namn!",
          icon: "error"
        });
      }
    });
  };

  const updateInbox = async (inbox: Inbox, inboxName: string) => {
    console.log('inboxName', inboxName, 'inboxId', inbox.id);
    const requestBody = {
      "id": inbox.id,
      "inbox_name": inboxName
    };
    try {
      await ax.patch(`/feedback_source`, requestBody)//Gör till mutation
        .then((response) => {
          queryClient.setQueryData(['feedback_source', 'all', 'list', { feedback_source_type_name: 'email' }], (oldData: Inbox[]) => {
            return oldData.map((i) => i.id === inbox.id ? response.data : i);
          });
          Swal.fire({
            title: "Inkorg uppdaterad!",
            icon: "success"
          });
        });
    } catch (error) {
      Swal.fire({
        title: "Uppdatering misslyckades!",
        text: "Försök igen senare",
        icon: "error"
      });
    }
  };

  const handleSyncInbox = async (inboxId: number) => {
    if (inboxId) {
      try {
        await ax.post(`/feedback_source/refresh/?feedback_source_id=${inboxId}`)
          .then(() => {
            queryClient.invalidateQueries({ queryKey: ['feedback_source', 'all', 'list', { feedback_source_type_name: 'email' }] });
          });
      } catch (error) {
        Swal.fire({
          title: "Synkronisering misslyckades!",
          text: "Försök igen senare",
          icon: "error"
        });
      }
    }
    else {
      Swal.fire({
        title: "Inkorg hittades inte",
        text: "Försök igen senare",
        icon: "error"
      });
    }
  };

  const handleActivateInbox = async (inboxId: number, activated: boolean) => {
    console.log('inboxId', inboxId, 'activated', activated);
    const requestBody = {
      "id": inboxId,
      "activated": activated
    };
    try {
      await ax.patch(`/feedback_source`, requestBody)
        .then((response) => {
          queryClient.setQueryData(['feedback_source', 'all', 'list', { feedback_source_type_name: 'email' }], (oldData: Inbox[]) => {
            return oldData.map((i) => i.id === inboxId ? response.data : i);
          });
        });
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <AdminListContainer>
      {feedbackSourceList?.length === 0 ? (
        <AdminListItem>Hittade inga inkorgar</AdminListItem>
      ) : (
        <>
          <AdminListItem>
            <div style={{fontWeight: '500', fontSize: '1.2rem'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Namn | E-postadress | Senast synkad | Aktiverad
          </div>
        </AdminListItem>
        {feedbackSourceList?.map((inbox: Inbox) => (
          <AdminListItem key={inbox.id}>
            <DeleteButton onClick={() => handleDeleteInboxPopup(inbox.id, inbox.name)}>
              <FaTrashCan />
            </DeleteButton>
            <ListConfigureButton onClick={() => handleConfigureInbox(inbox)}>
              <FaWrench />
            </ListConfigureButton>
            {inbox.name} | {inbox.config.email} | Senast synkad: {inbox.last_refresh_at ? formatDate(inbox.last_refresh_at) : 'Ej synkad ännu'}&nbsp;&nbsp;
            <DeleteButton onClick={() => handleSyncInbox(inbox.id)}>
              <FaSync />
            </DeleteButton>
            <div style={{display: 'inline-flex', alignItems: 'center'}}>
              &nbsp;&nbsp;| Aktiv:&nbsp;&nbsp;
              <input type="checkbox" checked={inbox.activated} onChange={(e) => handleActivateInbox(inbox.id, e.target.checked)} />
            </div>
          </AdminListItem>
        ))}
        </>
    )}
    </AdminListContainer>
  );
};

export default EmailInboxList;