import { useQuery } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";


export const useShowAssistantConfig = () => {
  const queryClient = useQueryClient();

  const {data: showAssistantConfig} = useQuery<boolean, Error>({
      queryKey: ['assistant_config', 'show'],
      queryFn: () => Promise.resolve(false),
      enabled: false,
      initialData: false,
  });

  const updateShowAssistantConfig = (show: boolean) => {
    queryClient.setQueryData(['assistant_config', 'show'], show);
  }

  return {showAssistantConfig, updateShowAssistantConfig};
}