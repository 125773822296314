import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ax } from "../Utils";
import { UserUnitAccess } from "../Types";




export const useUserUnitAccessMutation = () => {
  const queryClient = useQueryClient();

  const userUnitAccessMutation = useMutation({
    mutationFn: (variables: any) => {
      console.log('variables: ', variables);
      // if (requestBody.role_ids) {
      //   delete requestBody.role_names;
      //   return ax.post('/user_unit_access/many_roles', requestBody);
      // } 
      const requestBody = {...variables};
      if (requestBody.obj_id) {
        return ax.delete(`/user_unit_access/${requestBody.obj_id}`);
      }else {
        delete requestBody.role_name;
        delete requestBody.obj_id;
        return ax.post('/user_unit_access', requestBody);
      }
    },
    onSuccess: (response, variables) => {
      queryClient.setQueryData(['user_unit_access', 'all', 'list', {unit_id: variables.unit_id}], (oldData: UserUnitAccess[]) => {
        const editedUAA = oldData.find((uua) => uua.user_id === variables.user_id);
        if (editedUAA && variables.obj_id) {
          const newRoles = { ...editedUAA.roles };
          delete newRoles[variables.role_name as keyof typeof editedUAA.roles];
          const updatedUAA = {
            ...editedUAA,
            roles: newRoles
          };
          return oldData.map(uua => 
            uua.user_id === variables.user_id ? updatedUAA : { ...uua }
          );
        }
        else if (editedUAA) {
          const newRoles = { 
            ...editedUAA.roles,
            [variables.role_name]: {
              id: response.data.id,
              role_id: variables.role_id,
              role_name: variables.role_name,
            }
          };
          const updatedUAA = {
            ...editedUAA,
            roles: newRoles
          };
          return oldData.map(uua => 
            uua.user_id === variables.user_id ? updatedUAA : { ...uua }
          );
        }
        return [...oldData];
      });
    },
  });

  return userUnitAccessMutation;
}
