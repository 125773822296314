import { zodResolver } from "@hookform/resolvers/zod";
import { useAssistantLanguages } from "../../../Queries/useAssistantLanguages";
import { assistantBasicInfoSchema, TAssistantBasicInfoSchema } from "../../../Types";
import { AdminButton, AdminInputError, AdminSettingContainer, AdminSettingInput, AdminSettingSelectBox, AdminSettingText, AdminSettingTextarea, ConfigSectionContainer, ConfigSectionTitle } from "../../AdminPage-styling";
import { useForm } from "react-hook-form";
import { useAssistantConfigMutation } from "../../../Mutations/AssistantConfigMutation";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { useSelectedAssistant } from "../../../Queries/useSelectedAssistant";


const AssistantBasicInfoConfig = () => {

  const {data: assistantLanguages} = useAssistantLanguages();
  const {selectedAssistant, updateSelectedAssistant} = useSelectedAssistant();

  const assistantConfigMutation = useAssistantConfigMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    reset,
  } = useForm<TAssistantBasicInfoSchema>({
    resolver: zodResolver(assistantBasicInfoSchema),
  });

  useEffect(() => {
    if (!selectedAssistant && !isDirty) {
      reset({
        config_name: '',
        description: '',
        name: '',
        default_language_id: assistantLanguages?.[0]?.id || '',
        logo_url: '',
      });
    } else if (selectedAssistant && !isDirty) {
      reset({
        config_name: selectedAssistant.config_name,
        description: selectedAssistant.config_description,
        name: selectedAssistant.name,
        default_language_id: selectedAssistant.default_assistant_language_id,
        logo_url: selectedAssistant.logo_url,
      });
    }
  }, [selectedAssistant, reset, isDirty, assistantLanguages]);

  useEffect(() => {
    if (selectedAssistant?.id) {
      reset({
        config_name: selectedAssistant.config_name,
        description: selectedAssistant.config_description,
        name: selectedAssistant.name,
        default_language_id: selectedAssistant.default_assistant_language_id,
        logo_url: selectedAssistant.logo_url,
      });
    }
    else {
      reset({
        config_name: '',
        description: '',
        name: '',
        default_language_id: assistantLanguages?.[0]?.id || '',
        logo_url: '',
      });
    }
  }, [selectedAssistant?.id]);

  const onSubmit = async (data: TAssistantBasicInfoSchema) => {
    console.log(data);
    if (selectedAssistant?.id) {
      const requestBody = {
        id: selectedAssistant.id,
        config_name: data.config_name,
        config_description: data.description,
        default_assistant_language_id: data.default_language_id,
        logo_url: data.logo_url,
      }
      try {
        assistantConfigMutation.mutate(requestBody, {
          onSuccess: (response) => {
            updateSelectedAssistant(response.data);
            toast.success("Grundläggande info har sparats");
          }
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Något gick fel!',
          text: 'Försök igen senare.',
        })
      }
    }
    else {
      try {
        const requestBody = {
          config_name: data.config_name,
          config_description: data.description,
          name: data.name,
          default_assistant_language_id: data.default_language_id,
          logo_url: data.logo_url,
        }
        assistantConfigMutation.mutate(requestBody, {
          onSuccess: (response) => {
            updateSelectedAssistant(response.data);
            toast.success("Grundläggande info har sparats");
          }
        });
      }
      catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Något gick fel!',
          text: 'Försök igen senare.',
        })
      }
    }
  }

  return (
    <ConfigSectionContainer>
      <ConfigSectionTitle>Grundläggande info</ConfigSectionTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AdminSettingContainer>
          <AdminSettingText>Konfigurationsnamn</AdminSettingText>
          <AdminSettingInput
            type="text"
            placeholder="Namn"
            id="configName"
            {...register("config_name")}
          />
          {errors.config_name && 
            <AdminInputError>{errors.config_name.message}</AdminInputError>
          }
        </AdminSettingContainer>
        <AdminSettingContainer>
          <AdminSettingText>Assistentens namn</AdminSettingText>
          <AdminSettingInput
            type="text"
            placeholder="Namn"
            id="name"
            {...register("name")}
          />
          {errors.name && 
            <AdminInputError>{errors.name.message}</AdminInputError>
          }
        </AdminSettingContainer>
        <AdminSettingContainer>
          <AdminSettingText>Beskrivning</AdminSettingText>
          <AdminSettingTextarea
            placeholder="Beskrivning"
            rows={3}
            {...register("description")}
          />
        </AdminSettingContainer>
        <AdminSettingContainer>
          <AdminSettingText>Standardspråk</AdminSettingText>
          <AdminSettingSelectBox
            placeholder="Standardspråk"
            id="defaultLanguage"
            {...register("default_language_id")}
          >
            {assistantLanguages?.map((language) => (
              <option key={language.id} value={language.id}>{language.name}</option>
            ))}
          </AdminSettingSelectBox>
        </AdminSettingContainer>
        <AdminSettingContainer>
          <AdminSettingText>Bildlänk</AdminSettingText>
          <AdminSettingInput
            type="text"
            placeholder="www.exempel.se/ragge.png"
            id="logoUrl"
            {...register("logo_url")}
          />
        </AdminSettingContainer>
        <AdminButton type="submit" disabled={isSubmitting}>Spara</AdminButton>
      </form>
    </ConfigSectionContainer>
  )
}

export default AssistantBasicInfoConfig;