import styled, { css } from "styled-components";

export const AuthorizationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: safe center;
  justify-content: center;
  background-color: #fff;
  padding: 0 2.5rem 2.5rem 2.5rem;
  border-radius: 10px;
  width: 35rem;
  box-shadow: 0 0 0.7rem 0 rgba(0, 0, 0, 0.2);
`;

export const AuthorizationTitle = styled.p`
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  text-align: center;
`;

export const AuthorizationText = styled.p`
  font-size: 1.1rem;
  text-align: center;
`;

export const AuthorizationForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0 0;
  align-content: safe center;
`;

export const AuthorizationLabel = styled.label`
  font-size: 1.1rem;
  margin-bottom: 0.1rem;
  margin-top: 1rem;
`;

export const AuthorizationInput = styled.input`
  padding: 0.5rem 0.7rem;
  border-radius: 5px;
  border: 1px solid #333;
  font-size: 1.1rem;

  &::placeholder {
    color: #777;
    opacity: 1;
  }
`;

export const AuthorizationButton = styled.button`
  background-color: #0f86a1;
  color: #fff;
  border: 1px solid #333;
  border-radius: 5px;
  margin-top: 1.5rem;
  font-size: 1.1rem;
  padding: 0.5rem 0.7rem;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #0c6d85;
  }
  &:active {
    background-color: #0c6d85;
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  }
`;

export const AuthorizationError = styled.p`
  color: #ff0000;
  margin-bottom: 0;
`;

export const LinkSpan = styled.span`
  color: #0f86a1;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;


// Multistep form

export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 1.5rem 2.5rem; // Initial padding
  border-radius: 10px;
  width: 100%; // Full width to adapt to the screen size
  max-width: 75rem; // Maximum width for larger screens
  box-shadow: 0 0 0.7rem 0 rgba(0, 0, 0, 0.2), inset -0.2rem -0.3rem 3rem rgba(0, 0, 0, 0.15);
  margin: auto 1rem; // Centering with a margin on the sides

  @media (max-width: 800px) { // Adjust this breakpoint based on your layout needs
    padding: 1.5rem 1rem; // Reduce padding symmetrically as screen gets smaller
    margin: auto 0.5rem; // Optionally reduce margin if necessary
  }

  @media (max-width: 500px) { // For very small screens
    padding: 1.5rem 0.5rem; // Further reduce padding to ensure content fits without scrolling
    margin: auto 0.25rem; // Reduce margin to minimum to use the space effectively
  }
  @media (max-width: 425px) { // For very small screens
    padding: 1.5rem 0; // Further reduce padding to ensure content fits without scrolling
    margin: auto 0; // Reduce margin to minimum to use the space effectively
  }
`;

export const FormProgressNav = styled.nav`
  margin-bottom: 1rem;
`;

export const FormProgressList = styled.ol`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem; /* space-y-4 */
  @media (min-width: 768px) { /* md: breakpoint */
    flex-direction: row;
    gap: 2rem; /* md:space-x-8 */
    margin-top: 0; /* md:space-y-0 */
  }
`;

export const FormProgressListItem = styled.li`
  flex: 1; /* shorthand for flex-grow: 1, flex-shrink: 1, and now setting flex-basis to 0 */
  flex-basis: 0;
  @media (min-width: 768px) {
    flex-grow: 1;
  }
`;

type FormStepProps = {
  $isactive: boolean;
  $iscompleted: boolean;
};

export const FormProgressStep = styled.div<FormStepProps>`
  display: flex;
  flex-direction: column;
  border-left: 4px solid ${props => props.$isactive ? '#0f86a1' : props.$iscompleted ? '#0f86a1' : 'lightgray'};
  padding: 0.5rem 1rem; /* py-2 pl-4 */
  transition: color 0.3s;
  @media (min-width: 768px) {
    border-left: none;
    border-top: 0.4rem solid ${props => props.$isactive ? '#0f86a1' : props.$iscompleted ? '#0f86a1' : 'lightgray'};
    padding: 1rem 0 0; /* md:pt-4 md:pb-0 md:pl-0 */
  }
`;

export const FormProgressStepText = styled.span<FormStepProps>`
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* font-medium */
  color: ${props => props.$isactive || props.$iscompleted ? '#0f86a1' : 'gray'};
  transition: color 0.3s;
`;

export const FormProgressStepName = styled.span`
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* font-medium */
  color: #000;
`;

export const FormStepTitle = styled.p`
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 0;
  text-align: center;
`;

export const FormSelectBox = styled.select`
  padding: 0.5rem 0.7rem;
  border-radius: 5px;
  border: 1px solid #333;
  font-size: 1.1rem;
`;

export const PricingCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
`;

export const FormPricingCard = styled.div<{ $isSelected: boolean }>`
  width: 25%;
  height: 30rem;
  background: white;
  border-radius: 10px;
  margin: 0.5rem 0;
 
  box-shadow: ${props => props.$isSelected ? 'inset 0 -3rem 3rem rgba(15, 134, 161, 0.15),' : 'inset 0 -3rem 3rem rgba(0,0,0,0.1),'}
              ${props => props.$isSelected ? '0 0  0 2px #0f86a1,' : '0 0  0 2px rgb(190, 190, 190),'}
              0.1rem 0.3rem 0.75rem rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    width: 26%;
    height: 31rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: 0;
    box-shadow: ${props => props.$isSelected ? 'inset 0 -2rem 2rem rgba(15, 134, 161, 0.15),' : 'inset 0 -2rem 2rem rgba(0,0,0,0.1),'}
                ${props => props.$isSelected ? '0 0  0 2px #0f86a1,' : '0 0  0 2px rgb(190, 190, 190),'}
                0.05rem 0.3rem 0.75rem 0.1rem rgba(0, 0, 0, 0.4);
  }
`;
 
export const FormPricingCardTitle = styled.p`
  font-size: 2rem;
  margin: 1rem 0.75rem 0.75rem 0.75rem;
`;

export const FormPricingCardPrice = styled.p`
  font-size: 2rem;
  color: #0f86a1;
  font-weight: 500;
  margin: 0.5rem;
`;

export const FormPricingCardText = styled.p`
  font-size: 1rem;
  margin: 0.5rem;
  text-align: center;
`;

export const FormObligatory = styled.span`
  color: red;
`;

export const FormButtonContainer = styled.div`
  margin-top: 2.5rem;
  padding-top: 1.25rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

// Generic button styling
const NextButtonStyle = css<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0.6rem;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  color: #0f86a1;
  background-color: #ffffff;
  border-radius: 0.375rem;
  border: 2px solid transparent;
  border-color: #666;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #f0f9ff;
  }

  &:active {
    background-color: #e6f2f8;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        background-color: #ffffff;
      }
    `}
`;

// Styled button with conditional disabling
export const NavigationButton = styled.button<{ disabled?: boolean }>`
  ${NextButtonStyle}
`;

export const FormSubmitButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 0.8rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  background-color: #ffffff;
  border-radius: 0.375rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  background-color: #0f86a1;
  color: #ffffff;
  border: 2px solid transparent;
  border-color: #0f86a1;

  &:hover {
    background-color: #0c6d85;
  }

  &:active {
    background-color: #0b5c6f;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  }
`;