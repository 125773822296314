import { AdminMainContent, AdminCategoryContainer, AdminCategoryTitle, AdminSettingContainer, AdminSettingText, AdminSettingSelectBox, SmallTextSpan, AdminSettingTextarea, AdminButton } from "./AdminPage-styling";
//import AddUser from "./AdminComponents/AddUser";
import { Organization, Inbox, User } from "../Types";
import { useRef, useEffect, useState } from "react";
import { ax } from "../Utils";
import AddInbox from "./AdminComponents/FeedbackTab/AddInbox";
import AddWebsite from "./AdminComponents/AddWebsite";
import AddDocument from "./AdminComponents/AddDocument";
import { FaLanguage } from "react-icons/fa6";
import Swal from "sweetalert2";
import CompanyInboxList from "./AdminComponents/FeedbackTab/EmailInboxList";
import { useQueries, useQueryClient, useMutation } from "@tanstack/react-query";
import { useCurrentUser } from "../Queries/useCurrentUser";
import ChangeDescription from "./AdminComponents/ChangeDescription";
import { useOrganization } from "../Queries/useOrganization";
import { useAssistantLanguages } from "../Queries/useAssistantLanguages";
import { useInterfaceLanguages } from "../Queries/useInterfaceLanguages";
import WebScrapeConfigList from "./AdminComponents/WebScrapeConfigList";
import AssistantConfigPopUp from "./AdminComponents/AssistantConfigPopUp";
import { StandardBase } from "../Components/StandardBase";
import { UsersTable } from "./AdminComponents/UsersTable";
import { Tabs, Tab, Box } from '@mui/material';
import { People, Language as LanguageIcon, Email, Business, Language, Web, Description, Settings, Chat, Assistant, SmartToyOutlined } from '@mui/icons-material';
import { useCurrentTabAdmin } from "../Queries/useCurrentTabAdmin";
import ChatbotConfigTab from "./AdminComponents/ChatbotConfig/ChatbotConfigTab";
import AssistantConfig from "./AdminComponents/AssistantConfig/AssistantConfig";
import AdminFeedbackTab from "./AdminComponents/FeedbackTab/AdminFeedbackTab";

const AdminPage = () => {

  const [assistantLanguageId, setAssistantLanguageId] = useState<string>('');
  const [interfaceLanguageId, setInterfaceLanguageId] = useState<string>('');
  const [showAssistantConfigPopUp, setShowAssistantConfigPopUp] = useState(false);
  
  const queryClient = useQueryClient();
  
  const { data: currentUser } = useCurrentUser();
  const { data: organization, ...organizationQuery } = useOrganization(currentUser?.org_id);
  const { data: assistantLanguages, ...assistantLanguagesQuery } = useAssistantLanguages();
  const { data: interfaceLanguages, ...interfaceLanguagesQuery } = useInterfaceLanguages();
  const { currentTab, updateCurrentTab } = useCurrentTabAdmin();
  
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    updateCurrentTab(newValue);
  };




  const organizationMutation = useMutation({
    mutationFn: (requestBody: any) => {
      return ax.patch('/organization', requestBody);
    },
    onSuccess: (data, requestBody) => {
      queryClient.setQueryData(['organization', { obj_id: currentUser?.org_id }], data);
      queryClient.invalidateQueries({ queryKey: ['organization', { obj_id: currentUser?.org_id }] });
      if (requestBody.business_description) {
        Swal.fire({
          title: "Företagsbeskrivning uppdaterad!",
          icon: "success"
        });
      }
      else if (requestBody.default_assistant_language_id || requestBody.default_interface_language_id) {
        Swal.fire({
          title: "Språk uppdaterade!",
          icon: "success"
        });
      }
    },
    onError: (error, requestBody) => {
      if (requestBody.business_description) {
        Swal.fire({
          title: "Uppdatering av företagsbeskrivning misslyckades!",
          text: "Försök igen senare",
          icon: "error"
        });
      }
      else if (requestBody.default_assistant_language_id || requestBody.default_interface_language_id) {
        Swal.fire({
          title: "Uppdatering av språk misslyckades!",
          text: "Försök igen senare",
          icon: "error"
        });
      }
    }
  });

  const handleSaveBusinessDescription = async (newDescription: string) => {
    if (organization && newDescription !== organization.business_description) {
      const requestBody: any = {
        "id": organization.id,
        "business_description": newDescription,
      };
      organizationMutation.mutate(requestBody);
    }
  };

  const updateDefaultLanguages = async () => {
    if (organization && (assistantLanguageId !== organization.default_assistant_language_id || interfaceLanguageId !== organization.default_interface_language_id)) {
      const requestBody: any = {
        "id": organization.id,
        "default_assistant_language_id": assistantLanguageId,
        "default_interface_language_id": interfaceLanguageId,
      };
      console.log(requestBody);

      organizationMutation.mutate(requestBody);
    }
  };

  const toggleAssistantConfigPopUp = () => {
    setShowAssistantConfigPopUp(!showAssistantConfigPopUp);
  }





  return (
    <StandardBase>
      <AdminMainContent>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs 
          value={currentTab} 
          onChange={handleTabChange} 
          variant="scrollable" 
          scrollButtons="auto"
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#212121',
            },
            '& .MuiTab-root': {
              color: '#212121',
              '&.Mui-selected': {
                color: '#212121',
              },
            },
          }}
        >
          <Tab icon={<People />} label="Användare" />
          <Tab icon={<LanguageIcon />} label="Språk" />
          <Tab icon={<Email />} label="Feedback" />
          <Tab icon={<Business />} label="Företagsbeskrivning" /> {/* Baka in i inställningar med språk */}
          <Tab icon={<Web />} label="Källor" />
          <Tab icon={<Description />} label="Texter" /> {/* Baka in i källor */}
          <Tab icon={<Assistant />} label="Assistenten" />
          <Tab icon={<SmartToyOutlined />} label="Kundchatbot" />
        </Tabs>
      </Box>
        {currentTab === 0 ? (
        <AdminCategoryContainer>
          <UsersTable />
        </AdminCategoryContainer>
        ) : currentTab === 1 ? (
        <AdminCategoryContainer>
          <AdminCategoryTitle>Språk <FaLanguage/></AdminCategoryTitle>
          <AdminSettingContainer>
            <AdminSettingText>Hemsidans språk</AdminSettingText>
            <AdminSettingSelectBox
              defaultValue={organization?.default_interface_language_id}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setInterfaceLanguageId(event.target.value)}
            >
              {interfaceLanguages?.map((language) => (
                <option key={language.id} value={language.id}>{language.name}</option>
              ))}
            </AdminSettingSelectBox>
          </AdminSettingContainer>
          <AdminSettingContainer>
            <AdminSettingText>Assistentens språk</AdminSettingText>
            <AdminSettingSelectBox
              defaultValue={organization?.default_assistant_language_id}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setAssistantLanguageId(event.target.value)}
            >
              {assistantLanguages?.map((language) => (
                <option key={language.id} value={language.id}>{language.name}</option>
              ))}
            </AdminSettingSelectBox>
          </AdminSettingContainer>
          <AdminButton onClick={updateDefaultLanguages}>
            Spara
          </AdminButton>

        </AdminCategoryContainer>
        ) : currentTab === 2 ? (
        <AdminCategoryContainer>
          <AdminFeedbackTab />
        </AdminCategoryContainer>
        ) : currentTab === 3 ? (
        <AdminCategoryContainer>
          <ChangeDescription
            businessDescription={organization?.business_description || ""}
            handleSaveBusinessDescription={handleSaveBusinessDescription}
          />
        </AdminCategoryContainer>
        ) : currentTab === 4 ? (
        <AdminCategoryContainer>
          <AdminCategoryTitle>Hemsidor</AdminCategoryTitle>
          Lägg till en webbplats för ragge att läsa
          <AddWebsite />
          <AdminSettingContainer>
            <AdminSettingText>Webbplatser som ragge läser:</AdminSettingText>
            <WebScrapeConfigList />
          </AdminSettingContainer>
        </AdminCategoryContainer>
        ) : currentTab === 5 ? (
        <AdminCategoryContainer>
          <AdminCategoryTitle>Lägg till texter</AdminCategoryTitle>
          <AddDocument />
        </AdminCategoryContainer>
        ) : currentTab === 6 ? (
        <AdminCategoryContainer>
          <AssistantConfig />
        </AdminCategoryContainer>
        ) : currentTab === 7 ? (
        <AdminCategoryContainer>
          <ChatbotConfigTab />
        </AdminCategoryContainer>
        ) : null}
      </AdminMainContent>
      {showAssistantConfigPopUp && (
        <AssistantConfigPopUp
          toggleAssistantConfigPopUp={toggleAssistantConfigPopUp}
        />
      )}
    </StandardBase>
  );
};

export default AdminPage;