import { QueryClient } from "@tanstack/react-query";

// Add a unique identifier to the queryClient instance
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: 1000 * 60 * 60 * 24,
    },
  },
});


export { queryClient };