import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UNAUTHORIZED_EVENT } from '../Utils';

export const UnauthorizedHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleUnauthorized = () => {
      navigate('/login');
    };

    window.addEventListener(UNAUTHORIZED_EVENT, handleUnauthorized);
    return () => {
      window.removeEventListener(UNAUTHORIZED_EVENT, handleUnauthorized);
    };
  }, [navigate]);

  return null;
};