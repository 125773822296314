import { useQuery } from "@tanstack/react-query";
import { ax } from "../Utils";
import { UserMeta } from "../Types";



export const useUserMeta = () => {
  return useQuery<UserMeta, Error>({
    queryKey: ['users', 'meta'],
    queryFn: (): Promise<UserMeta> => ax.get(`/users/meta/`).then(res => res.data),
  });
}