import { useQuery } from "@tanstack/react-query"
import { ax } from "../Utils"
import { Role } from "../Types"



export const useRoleList = () => {
  return useQuery<Role[], Error>({
    queryKey: ['role','all'],
    queryFn: () => ax.get('/role/all/').then(res => res.data),
  })
}