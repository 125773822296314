import { useQuery, useQueryClient } from "@tanstack/react-query";


export const useCurrentTabAdmin = () => {
  const queryClient = useQueryClient();

  const {data: currentTab} = useQuery<number, Error>({
    queryKey: ['current', 'tab', 'admin'],
    queryFn: () => Promise.resolve(0),
    enabled: false,
    initialData: 0,
  });

  const updateCurrentTab = (tab: number) => {
    queryClient.setQueryData<number>(['current', 'tab', 'admin'], tab);
  };

  return { currentTab, updateCurrentTab }
};