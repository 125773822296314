import ZoidChatbotWidget from "./ZoidCodeForClients/ZoidChatbotWidget";
import { LandingHeader, MainContent } from "../LandingPage/LandingPage-styling";
import { ax } from "../Utils";
import { StandardBase } from "../Components/StandardBase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelectedChatbot } from "../Queries/useSelectedChatbot";
import { AdminButton, GrayAdminButton } from "../AdminPage/AdminPage-styling";

//const obj_id = "873e7553-38ee-48b9-a571-f53a28df2432";

const ExternalChatbotTestPage = () => {

  const [clientKey, setClientKey] = useState("");

  const {selectedChatbot, updateSelectedChatbot} = useSelectedChatbot();

  

  // useEffect(() => { //gör useQuery istället
  //   ax.get(`chatbot_config?obj_id=${obj_id}`)
  //     .then((res) => {
  //       setClientKey(res.data.api_key);
  //     });
  // }, []);

  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate('/admin');
  }


  return (
    <StandardBase>
      <MainContent>
        <LandingHeader>Testsida för kundchatboten</LandingHeader>
        
        {!selectedChatbot ?
        <>
          <h1 style={{textAlign: 'center', marginTop: '0px', fontWeight: '500'}}>
            Ingen chatbot vald för testning, gå tillbaka till admin och välj en chatbot.
          </h1>
        </>
        : !selectedChatbot?.api_key ?
        <>
          <h1 style={{textAlign: 'center', marginTop: '0px', fontWeight: '500'}}>
            Vald chatbot är inte aktiverad, gå tillbaka till admin och försök igen.
          </h1>
        </>
        :
        <>
          <h1 style={{textAlign: 'center', marginTop: '0px', fontWeight: '500'}}>
            Klicka på knappen i det nedre högra hörnet för att öppna chatten och testa kundernas upplevelse!
          </h1>
        </>
        }
        <GrayAdminButton onClick={handleBackButton}>Tillbaka till Admin</GrayAdminButton>
      </MainContent>
      {selectedChatbot?.api_key &&
        <ZoidChatbotWidget clientKey={selectedChatbot.api_key} />
      }
    </StandardBase>
  );
};

export default ExternalChatbotTestPage;