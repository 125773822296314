import { MuiColorInput } from "mui-color-input"
import { AdminButton, AdminInputError, AdminSettingForm, AdminSettingInput, AdminSettingText, ConfigSectionContainer, ConfigSectionTitle } from "../../AdminPage-styling"
import { AdminSettingContainer } from "../../AdminPage-styling"
import { SetStateAction, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { ChatbotConfig, chatbotAppearanceSchema, TChatbotAppearanceSchema } from "../../../Types"
import { zodResolver } from "@hookform/resolvers/zod"
import { AuthorizationError } from "../../../AuthorizationPages/Authorization-styling"
import { ax } from "../../../Utils"
import Swal from "sweetalert2"
import { useSelectedChatbot } from "../../../Queries/useSelectedChatbot"
import { useChatbotAssistantConfig } from "../../../Queries/useChatbotAssistantConfig"
import { toast } from "react-toastify"
import { useQueryClient } from "@tanstack/react-query"
import { useChatbotConfigMutation } from "../../../Mutations/ChatbotConfigMutation"
import { useAssistantConfigMutation } from "../../../Mutations/AssistantConfigMutation"



const AppearanceConfig = () => {

  const [primaryColor, setPrimaryColor] = useState('');

  const {selectedChatbot, updateSelectedChatbot} = useSelectedChatbot();
  const {data: assistantConfig} = useChatbotAssistantConfig(selectedChatbot?.assistant_config_id);

  const chatbotConfigMutation = useChatbotConfigMutation();
  const assistantConfigMutation = useAssistantConfigMutation();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    reset,
  } = useForm<TChatbotAppearanceSchema>({
    resolver: zodResolver(chatbotAppearanceSchema),
  });

  useEffect(() => {
    if (!selectedChatbot && !isDirty) {
      reset({
        bot_name: '',
        logo_url: '',
        primary_color: '#0F86A1',
      });
      setPrimaryColor('#0F86A1');
    } else if (assistantConfig && !isDirty) {
      reset({
        bot_name: assistantConfig.name,
        logo_url: assistantConfig.logo_url,
      });
    }
  }, [assistantConfig, reset, isDirty]);

  useEffect(() => {
    if (selectedChatbot?.id) {
      reset({
        bot_name: assistantConfig?.name,
        logo_url: assistantConfig?.logo_url,
      });
      setPrimaryColor(selectedChatbot.theme || '#0F86A1');
    }
    else {
      reset({
        bot_name: '',
        logo_url: '',
        //primary_color: '#0F86A1',
      });
      setPrimaryColor('#0F86A1');
    }
  }, [selectedChatbot?.id]);

  useEffect(() => {
    if (selectedChatbot?.theme) {
      setPrimaryColor(selectedChatbot.theme);
    }
  }, [selectedChatbot?.theme]);


  const onSubmit = async (data: TChatbotAppearanceSchema) => {
    console.log(data);

    if (selectedChatbot?.id) {
      const requestBody = {
        id: selectedChatbot.id,
        theme: data.primary_color,
      };
      try {
        chatbotConfigMutation.mutate(requestBody, {
          onSuccess: (response) => {
            updateSelectedChatbot(response.data);
            if (response.data.assistant_config_id) {
              const requestBody2 = {
                id: response.data.assistant_config_id,
                name: data.bot_name,
                logo_url: data.logo_url,
                for_external_chatbot: true,
              }
              assistantConfigMutation.mutate(requestBody2, {
                onSuccess: () => {
                  toast.success('Utseende har sparats');
                }
              });
            }
            else {
              const requestBody2 = {
                name: data.bot_name,
                logo_url: data.logo_url,
                for_external_chatbot: true,
              }
              assistantConfigMutation.mutate(requestBody2, {
                onSuccess: (response2) => {
                  const requestBody3 = {
                    assistant_config_id: response2.data.id,
                    id: response.data.id,
                  }
                  chatbotConfigMutation.mutate(requestBody3, {
                    onSuccess: (response3) => {
                      updateSelectedChatbot(response3.data);
                      toast.success('Utseende har sparats');
                    }
                  });
                }
              });
            }
          }
        });
      } catch (error) {
        Swal.fire({
          title: 'Något gick fel',
          text: 'Något gick fel, försök igen senare',
          icon: 'error',
        })
      }
    }
    else {
      try {
        const requestBody ={
          name: data.bot_name,
          logo_url: data.logo_url,
          for_external_chatbot: true,
        };
        assistantConfigMutation.mutate(requestBody, {
          onSuccess: (response) => {
            queryClient.setQueryData(['assistant_config', {obj_id: response.data.id}], response.data);
            const requestBody2 = {
              assistant_config_id: response.data.id,
              theme: data.primary_color,
            }
            chatbotConfigMutation.mutate(requestBody2, {
              onSuccess: (response2) => {
                updateSelectedChatbot(response2.data);
                toast.success('Utseende har sparats');
              }
            });
          }
        });
      } catch (error) {
        Swal.fire({
          title: 'Något gick fel',
          text: 'Något gick fel, försök igen senare',
          icon: 'error',
        })
      }
    }

  }

  return (
    <ConfigSectionContainer>
      <ConfigSectionTitle>Utseende</ConfigSectionTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AdminSettingContainer>
          <AdminSettingText>Botens namn</AdminSettingText>
          <AdminSettingInput 
            type="text"
            placeholder="Namn"
            id="botName"
            {...register("bot_name")}
          />
          {errors.bot_name && (
            <AdminInputError>{`${errors.bot_name.message}`}</AdminInputError>
          )}
        </AdminSettingContainer>
        <AdminSettingContainer>
          <AdminSettingText>Primärfärg</AdminSettingText>
          <MuiColorInput
            value={primaryColor}
            {...register("primary_color")}
            onChange={(color) => setPrimaryColor(color)}
            format="hex"
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: '#fff',
                borderRadius: '6px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)',
                fontSize: '1rem',
                fontFamily: 'inherit',
                color: '#000',
                width: 'fit-content',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.5)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.1)',
                },
                '& input': {
                  padding: '8px 8px 7px 2px',
                  textTransform: 'uppercase',
                  fontSize: 'inherit',
                  fontFamily: 'inherit',
                },
              },
              '& .MuiInputAdornment-root': {
                marginRight: '5px',
              },
            }}
          />
          {errors.primary_color && (
            <AdminInputError>{`${errors.primary_color.message}`}</AdminInputError>
          )}
        </AdminSettingContainer>
        <AdminSettingContainer>
          <AdminSettingText>Bildlänk</AdminSettingText>
          <AdminSettingInput 
            type="text"
            placeholder="exempel.com/image.png"
            id="botImage"
            {...register("logo_url")}
          />
          {errors.logo_url && (
            <AdminInputError>{`${errors.logo_url.message}`}</AdminInputError>
          )}
        </AdminSettingContainer>
        <AdminButton
          type="submit"
          disabled={isSubmitting}
        >Spara</AdminButton>
      </form>
    </ConfigSectionContainer>
  )
}

export default AppearanceConfig;