import { useQuery } from "@tanstack/react-query";
import { ax } from "../Utils";
import { UserUnitAccess } from "../Types";



export const useCurrentUserUnitAccess = (unit_id: string | undefined) => {
    return useQuery<UserUnitAccess, Error>({
        queryKey: ['user_unit_access', 'all', 'current_user', { unit_id }],
        queryFn: () => ax.get('/user_unit_access/all/current_user/').then(res => res.data),
        enabled: !!unit_id,
        refetchOnReconnect: true,
    });
};