import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ax } from "../Utils";
import { ChatbotConfig } from "../Types";



export const useChatbotConfigMutation = () => {
  const queryClient = useQueryClient();

  const chatbotConfigMutation = useMutation({
    mutationFn: (requestBody: any) => {
      console.log('requestBody: ', requestBody);
      if (requestBody.id) {
        return ax.patch('/chatbot_config', requestBody);
      } else {
        return ax.post('/chatbot_config', requestBody);
      }
    },
    onSuccess: (response, variables) => {
      if (variables.id) {
        queryClient.setQueryData(['chatbot_config', 'all'], (oldData: ChatbotConfig[]) => {
          return oldData.map((config) => config.id === variables.id ? response.data : config);
        });
      }
      else {
        queryClient.setQueryData(['chatbot_config', 'all'], (oldData: ChatbotConfig[]) => {
          return [...oldData, response.data];
        });
      }
    }
  });

  return chatbotConfigMutation;
}