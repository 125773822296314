import styled, { keyframes } from 'styled-components';

export const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%); 
  width: 100%;
  height: 100vh;
  max-width: 1440px;
  overflow: hidden;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: safe center;
  width: 100%;
  min-width: 735px;
  height: 100%;
  overflow: auto;
  color: #fff;
`;

export const HeaderSection = styled.div`
  display: flex;
  width: 995px;
  align-self: safe center;
  padding-top: 0vh;
  flex-direction: column;
`;

export const LandingHeader = styled.p`
  font-size: 72px;
  color: #fff;
  /* text-shadow:
    -2px -2px 0 rgba(0, 0, 0, 0.3),
    0   -2px 0 rgba(0, 0, 0, 0.3),
    2px -2px 0 rgba(0, 0, 0, 0.3),
    2px  0   0 rgba(0, 0, 0, 0.3),
    2px  2px 0 rgba(0, 0, 0, 0.3),
    0    2px 0 rgba(0, 0, 0, 0.3),
    -2px  2px 0 rgba(0, 0, 0, 0.3),
    -2px  0   0 rgba(0, 0, 0, 0.3),
    0 5px 5px rgba(0, 0, 0, 0.5); */
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
  text-align: center;
  margin-bottom: 60px;
`;
//glowing text
//text-shadow: 0 0 10px #e60073, 0 0 20px #e60073, 0 0 30px #e60073, 0 0 40px #e60073;

export const ColorSpan = styled.span`
  color: #0f86a1;
`;

export const ButtonContainer = styled.div`
  justify-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 75px;
`;

export const GetStartedButton = styled.button`
  border-radius: 12px;
  border: none;
  font-size: 30px;
  padding: 20px 45px 20px 45px;
  color: #fff;
  background-color: #0f86a1;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.45);
  cursor: pointer;
  align-self: center;

  &:hover {
    background-color: #085e71;
    box-shadow: 0 0 10px #0f86a1;
  }

  &:active {
    background-color: #065c6f;
    box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.6), 0 0 10px #0f86a1;
    font-size: 29px;
    padding: 20px 46px 20px 46px;
    color: #eee;
  }
`;

export const Words = styled.div`
  overflow: hidden;
`;

const cycleWordsAnimation = keyframes`
  10% {
    transform: translateY(-105%);
  }
  25% {
    transform: translateY(-100%);
  }
  35% {
    transform: translateY(-205%);
  }
  50% {
    transform: translateY(-200%);
  }
  60% {
    transform: translateY(-305%);
  }
  75% {
    transform: translateY(-300%);
  }
  85% {
    transform: translateY(-405%);
  }
  100% {
    transform: translateY(-400%);
  }
`;

export const Word = styled.span`
  display: block;
  height: 100%;
  animation: ${cycleWordsAnimation} 10s infinite;
  color: #0f86a1;
  text-shadow:
    -2px -2px 0 rgba(0, 0, 0, 0.3),
    0   -2px 0 rgba(0, 0, 0, 0.3),
    2px -2px 0 rgba(0, 0, 0, 0.3),
    2px  0   0 rgba(0, 0, 0, 0.3),
    2px  2px 0 rgba(0, 0, 0, 0.3),
    0    2px 0 rgba(0, 0, 0, 0.3),
    -2px  2px 0 rgba(0, 0, 0, 0.3),
    -2px  0   0 rgba(0, 0, 0, 0.3),
    0 5px 5px rgba(0, 0, 0, 0.5);
`;

export const Loader = styled.div`
  font-weight: 500;
  font-size: 64px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 90px;
  padding: 10px 0px 2px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 8px;
`;

export const LandingMidSection = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 80px);
  min-width: 400px;
  padding: 20px 20px 0px 20px;
  margin-bottom: 10px;
  background-color: rgba(23, 23, 23, 0.95);
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow:
    inset 0px 0px 4px 0px rgba(0, 0, 0, 0.4),
    0 0 5px 0 #0f86a1;
`;

export const LandingMidText = styled.p`
  font-size: 64px;
  color: #fff;
  text-shadow:
    -2px -2px 0 rgba(0, 0, 0, 0.3),
    0   -2px 0 rgba(0, 0, 0, 0.3),
    2px -2px 0 rgba(0, 0, 0, 0.3),
    2px  0   0 rgba(0, 0, 0, 0.3),
    2px  2px 0 rgba(0, 0, 0, 0.3),
    0    2px 0 rgba(0, 0, 0, 0.3),
    -2px  2px 0 rgba(0, 0, 0, 0.3),
    -2px  0   0 rgba(0, 0, 0, 0.3),
    0 5px 5px rgba(0, 0, 0, 0.5);
  margin: 0 0 20px 0;
  text-align: end;
  max-width: 855px;
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 40px);
  min-height: 300px;
  padding: 20px 20px 0px 20px;
  justify-content: space-between;
  margin-bottom: 80px;
`;

export const InfoCategoryCard = styled.div`
  max-height: 700px;
  width: 31%;
  min-width: 225px;
  background-color:  rgba(23, 23, 23, 0.95);
  border-radius: 6px;
  padding: 10px 10px 5px 10px;
  margin-right: max(8px, 1.5%);
  display: flex;
  flex-direction: column;
  justify-content: safe start;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow:
    inset 0px 0px 4px 0px rgba(0, 0, 0, 0.4),
    0 0 5px 0 #0f86a1;

  &:last-child {
    margin-right: 0px;
  }
`;

export const InfoCategoryHeader = styled.p`
  font-size: 32px;
  color: #fff;
  margin-top: 0px;
  margin-bottom: 10px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
`;

export const InfoCategoryText = styled.p`
  font-size: 20px;
  color: #fff;
`;

export const DarkBottomSection = styled.div`
  background: rgba(0, 0, 0, 0.9);
  width: 100vw;
  height: 300px;
  z-index: 1;
  display: flex;
  justify-content: center;
`;

export const BottomInfoSection = styled.div`
  width: 100%;
  max-width: 1400px;
  height: calc(100% - 40px);
  color: #fff;
  padding: 20px;
`;



const bounceAnimation = keyframes`
  85%, 92%, 100% {
    transform: translateY(0);
  }
  89% {
    transform: translateY(-4px);
  }
  95% {
    transform: translateY(2px);
  }
`;

const slideAnimation = keyframes`
  5% {
    transform: translateX(14px);
  }
  15%, 30% {
    transform: translateX(6px);
  }
  40%, 55% {
    transform: translateX(0);
  }
  65%, 70% {
    transform: translateX(-4px);
  }
  80%, 89% {
    transform: translateX(-12px);
  }
  100% {
    transform: translateX(14px);
  }
`;

const paperAnimation = keyframes`
  5% {
    transform: translateY(46px);
  }
  20%, 30% {
    transform: translateY(34px);
  }
  40%, 55% {
    transform: translateY(22px);
  }
  65%, 70% {
    transform: translateY(10px);
  }
  80%, 85% {
    transform: translateY(0);
  }
  92%, 100% {
    transform: translateY(46px);
  }
`;

const keyboardAnimation = keyframes`
5%, 12%, 21%, 30%, 39%, 48%, 57%, 66%, 75%, 84% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}

9% {
  box-shadow: 15px 2px 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}

18% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 2px 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}

27% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 12px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}

36% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 12px 0 var(--key), 60px 12px 0 var(--key), 68px 12px 0 var(--key), 83px 10px 0 var(--key);
}

45% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 2px 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}

54% {
  box-shadow: 15px 0 0 var(--key), 30px 2px 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}

63% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 12px 0 var(--key);
}

72% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 2px 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}

81% {
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 12px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
}`;

export const Typewriter = styled.div`
  --blue: #5C86FF;
  --blue-dark: #275EFE;
  --key: #fff;
  --paper: #EEF0FD;
  --text: #D3D4EC;
  --tool: #FBC56C;
  --duration: 3s;
  position: relative;
  animation: ${bounceAnimation} var(--duration) linear infinite;
  margin: 30px 50px 30px 150px;
`;

export const Slide = styled.div`
  width: 92px;
  height: 20px;
  border-radius: 3px;
  margin-left: 14px;
  transform: translateX(14px);
  background: linear-gradient(var(--blue), var(--blue-dark));
  animation: ${slideAnimation} var(--duration) ease infinite;
`;

export const SlideContent = styled.div`
  position: relative;
  z-index: 2;
  color: black;
  padding: 5px;
`;

export const Paper = styled.div`
  position: absolute;
  left: 24px;
  top: -26px;
  width: 40px;
  height: 46px;
  border-radius: 5px;
  background: var(--paper);
  transform: translateY(46px);
  animation: ${paperAnimation} var(--duration) linear infinite;
`;

export const Keyboard = styled.div`
  width: 120px;
  height: 56px;
  margin-top: -10px;
  z-index: 1;
  position: relative;
  background: linear-gradient(135deg, var(--blue), var(--blue-dark));
  border-radius: 7px;
  transform: perspective(10px) rotateX(2deg);
  transform-origin: 50% 100%;
  overflow: hidden; // Ensure content inside is visible
  animation: ${keyboardAnimation} var(--duration) linear infinite;
`;

export const KeyboardKeys = styled.div`
  position: relative;
  height: 100%;
`;

export const Key = styled.div`
  position: absolute;
  width: 11px;
  height: 4px;
  border-radius: 2px;
  background: var(--key);
`;