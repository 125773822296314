import { LandingHeader, MainContent } from "../LandingPage/LandingPage-styling";
import { Container, ContentContainer, Logo, NavBar, PageBackground, ScreenProtector } from "../Styling/NavBar-styling";
import HamburgerMenu from "../Components/HamburgerMenu";
import { useNavigate } from "react-router-dom";

const NoAccessPage = () => {

  const navigate = useNavigate();

  const handleHome = () => {
    navigate('/user');
  }

  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <Logo onClick={handleHome} >ragge.ai</Logo>
            <HamburgerMenu />
          </NavBar>
          <ContentContainer>
            <MainContent>
              <LandingHeader>
                Du har inte tillgång till denna sida!
              </LandingHeader>
              <h1 style={{textAlign: 'center', marginTop: '0px', fontWeight: '500'}}>
                Testa att byta enhet i menyn till höger.
              </h1>
            </MainContent>
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
  );
};


export default NoAccessPage;