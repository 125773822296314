import { useQuery } from "@tanstack/react-query";
import { AssistantConfig } from "../Types";
import { ax } from "../Utils";



export const useChatbotAssistantConfig = (obj_id: string | undefined) => {
  return useQuery<AssistantConfig | null>({
    queryKey: ['assistant_config', {obj_id}],
    queryFn: () => ax.get(`/assistant_config?obj_id=${obj_id}`).then(res => res.data),
    enabled: !!obj_id,
  });
}