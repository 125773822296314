import { useMutation } from "@tanstack/react-query";
import { ax } from "../Utils";



export const useActiveModuleAssistantConfigMutation = () => {

  const activeModuleAssistantConfigMutation = useMutation({
    mutationFn: (requestBody: any) => {
      return ax.patch(`/active_module/set_assistant_config/?assistant_config_id=${requestBody.assistant_config_id}&module_name=${requestBody.module_name}&create_if_not_exists=true`);
    },
  })

  return activeModuleAssistantConfigMutation;
}