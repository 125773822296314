import { AdminButton, AdminCategoryTitle, AdminSettingContainer, AdminSettingForm, AdminSettingTextarea, SmallTextSpan } from "../AdminPage-styling";

type ChangeDescriptionProps = {
  businessDescription: string;
  handleSaveBusinessDescription: (description: string) => void;
};

export default function ChangeDescription(props: ChangeDescriptionProps) {


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const inputElement = e.currentTarget[0] as HTMLInputElement;
    props.handleSaveBusinessDescription(inputElement.value);
  };

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <AdminSettingContainer>
      <AdminCategoryTitle>Företagsbeskrivning <SmallTextSpan>(Valfritt men rekommenderat då det hjälper assistenten att förstå sin uppgift)</SmallTextSpan></AdminCategoryTitle>
        <AdminSettingTextarea
          placeholder="Skriv en kort beskrivning av ditt företag här..."
          rows={5}
          defaultValue={props.businessDescription}
        />
      </AdminSettingContainer>
      <AdminButton 
      type="submit"
      >
        Uppdatera beskrivning
      </AdminButton>
    </form>
  );
}