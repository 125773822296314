import { Navigate } from 'react-router-dom';
import { useCurrentUserUnitAccess } from '../Queries/useCurrentUserUnitAccess';
import { UserUnitAccess } from '../Types';
import { StandardBase } from '../Components/StandardBase';
import { useCurrentUser } from '../Queries/useCurrentUser';
import { LandingHeader } from '../LandingPage/LandingPage-styling';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRole: keyof UserUnitAccess['roles'];
}

export const ProtectedRoute = ({ children, requiredRole }: ProtectedRouteProps) => {
  const { data: currentUser } = useCurrentUser();
  const { data: currentUserUnitAccess, isLoading, isFetched } = useCurrentUserUnitAccess(currentUser?.unit_id);

  if (isLoading || !isFetched) {
    return <StandardBase><LandingHeader>Loading...</LandingHeader></StandardBase>;
  }

  if (!currentUserUnitAccess?.roles?.[requiredRole]) {
    console.log('requiredRole', requiredRole);
    console.log('currentUserUnitAccess', currentUserUnitAccess);
    if (requiredRole === 'reader') {
      return <Navigate to="/no-access" replace />;
    }
    return <Navigate to="/user" replace />;
  }

  return <>{children}</>;
};