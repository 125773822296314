import { useForm } from "react-hook-form";
import { AdminButton, AdminSettingContainer, AdminSettingText, AdminSettingTextarea, ConfigSectionContainer, ConfigSectionTitle } from "../../AdminPage-styling";
import { chatbotBehaviorSchema, TChatbotBehaviorSchema } from "../../../Types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useSelectedAssistant } from "../../../Queries/useSelectedAssistant";
import { toast } from "react-toastify";
import { useAssistantConfigMutation } from "../../../Mutations/AssistantConfigMutation";
import Swal from "sweetalert2";



const AssistantBehaviorConfig = () => {

  const { selectedAssistant } = useSelectedAssistant();

  const assistantConfigMutation = useAssistantConfigMutation();
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    reset,
  } = useForm<TChatbotBehaviorSchema>({
    resolver: zodResolver(chatbotBehaviorSchema),
  });

  useEffect(() => {
    if (!selectedAssistant && !isDirty) {
      reset({
        background: '',
        goal: '',
        tonality: '',
        scope: '',
        purpose: '',
      });
    } else if (selectedAssistant && !isDirty) {
      reset({
        background: selectedAssistant.background,
        goal: selectedAssistant.goal,
        tonality: selectedAssistant.tonality,
        scope: selectedAssistant.scope,
        purpose: selectedAssistant.purpose,
      });
    }
  }, [selectedAssistant, reset, isDirty]);

  useEffect(() => {
    if (selectedAssistant?.id) {
      reset({
        background: selectedAssistant.background,
        goal: selectedAssistant.goal,
        tonality: selectedAssistant.tonality,
        scope: selectedAssistant.scope,
        purpose: selectedAssistant.purpose,
      });
    }
    else {
      reset({
        background: '',
        goal: '',
        tonality: '',
        scope: '',
        purpose: '',
      });
    }
  }, [selectedAssistant?.id]);

  const onSubmit = async (data: TChatbotBehaviorSchema) => {
    console.log(data);
    if (selectedAssistant?.id) {
      const requestBody = {
        id: selectedAssistant.id,
        background: data.background,
        goal: data.goal,
        tonality: data.tonality,
        scope: data.scope,
        purpose: data.purpose,
      }
      try {
        assistantConfigMutation.mutate(requestBody, {
          onSuccess: (response) => {
            toast.success('Beteende har sparats');
          }
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Något gick fel',
          text: 'Försök igen senare',
        });
      }
    }
    else {
      const requestBody = {
        background: data.background,
        goal: data.goal,
        tonality: data.tonality,
        scope: data.scope,
        purpose: data.purpose,
      }
      try {
        assistantConfigMutation.mutate(requestBody, {
          onSuccess: (response) => {
            toast.success('Beteende har sparats');
          }
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Något gick fel',
          text: 'Försök igen senare',
        });
      }
    }
  }

  return (
    <ConfigSectionContainer>
        <ConfigSectionTitle>Beteende</ConfigSectionTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AdminSettingContainer>
            <AdminSettingText>Bakgrund</AdminSettingText>
            <AdminSettingTextarea
              placeholder="Bakgrund"
              rows={3}
              {...register("background")}
            />
          </AdminSettingContainer>
          <AdminSettingContainer>
            <AdminSettingText>Mål</AdminSettingText>
            <AdminSettingTextarea
              placeholder="Mål"
              rows={3}
              {...register("goal")}
            />
          </AdminSettingContainer>
          <AdminSettingContainer>
            <AdminSettingText>Syfte</AdminSettingText>
            <AdminSettingTextarea
              placeholder="Syfte"
              rows={3}
              {...register("purpose")}
            />
          </AdminSettingContainer>
          <AdminSettingContainer>
            <AdminSettingText>Tonalitet</AdminSettingText>
            <AdminSettingTextarea
              placeholder="Tonalitet"
              rows={3}
              {...register("tonality")}
            />
          </AdminSettingContainer>
          <AdminSettingContainer>
            <AdminSettingText>Omfattning</AdminSettingText>
            <AdminSettingTextarea
              placeholder="Omfattning"
              rows={3}
              {...register("scope")}
            />
          </AdminSettingContainer>
          <AdminButton 
            type="submit"
            disabled={isSubmitting}
          >
            Spara
          </AdminButton>
        </form>
      </ConfigSectionContainer>
  )
}

export default AssistantBehaviorConfig;