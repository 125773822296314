import { useEffect, useState, InputHTMLAttributes } from "react"
import { Column, ColumnFiltersState, ColumnDef, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable, flexRender } from "@tanstack/react-table";
import './Tables.css'
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

interface TableProps {
  columns: ColumnDef<any, any>[];
  data: any[];
}

export default function Table ({columns, data}: TableProps) {

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    []
  )
  
  const table = useReactTable({
    data: data ?? [],
    columns,
    filterFns: {},
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  return (
    <div className="component-container">
      <div className="table-container">
        <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: "header-text",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {header.column.getIsSorted() === "asc" ? (
                              <span>&nbsp;<FaSortUp /></span>
                            ) : header.column.getIsSorted() === "desc" ? (
                              <span>&nbsp;<FaSortDown /></span>
                            ) : header.column.getCanSort() ? (
                              <span>&nbsp;<FaSort /></span>
                            ) : null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div className="header-filter">
                              <Filter column={header.column} />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="h-2" />
      <div className="pagination-container">
        <div>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        </div>
        <strong>
          Sida{' '}
          <input
            type="number"
            min="1"
            max={table.getPageCount()}
            value={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="current-page-input"
          />
          {/* {table.getState().pagination.pageIndex + 1}  */}
          {' '}av{' '}
          {table.getPageCount()}
        </strong>
      
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Visa {pageSize}
            </option>
          ))}
        </select>
        <div>
          <button
            className="border rounded p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
        </div>
      </div>
      {/* <div>{table.getPrePaginationRowModel().rows.length} Användare</div> */}
      {/* <div>
        <button onClick={() => rerender()}>Force Rerender</button>
      </div>
      <div>
        <button onClick={() => refreshData()}>Refresh Data</button>
      </div>
      <pre>
        {JSON.stringify(
          { columnFilters: table.getState().columnFilters },
          null,
          2
        )}
      </pre> */}
    </div>
  )
}

function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue()
  const { filterVariant } = column.columnDef.meta ?? {}

  return filterVariant === undefined ? (
    <></>
  ) : filterVariant === 'select' ? (
    <select
      onChange={e => {
        if (e.target.value === '') {
          column.setFilterValue(undefined)
        } else {
          column.setFilterValue(e.target.value === 'true')
        }
      }}
      value={columnFilterValue?.toString()}
    >
      {/* See faceted column filters example for dynamic select options */}
      <option value="">Alla</option>
      <option value="true">Ja</option>
      <option value="false">Nej</option>
    </select>
  ) : (
    <DebouncedInput
      onChange={value => column.setFilterValue(value)}
      placeholder={`Sök...`}
      type="text"
      value={(columnFilterValue ?? '') as string}
    />
  )
}

  // A typical debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
  }: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
  } & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input className="w-36 border shadow rounded" {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}
