import { useQuery } from "@tanstack/react-query";
import { User } from "../Types";
import { ax } from "../Utils";



export const useOrganizationUsersExclUnit = (unitId: string | undefined) => {
  return useQuery<User[], Error>({
    queryKey: ['users', 'all', 'exclude_current_unit', { unit_id: unitId }],
    queryFn: (): Promise<User[]> => ax.get(`/users/all/?exclude_current_unit=true`).then(res => res.data),
    enabled: !!unitId,
  });
}