import { useQuery, useQueryClient } from "@tanstack/react-query"
import { ChatbotConfig } from "../Types";


export const useSelectedChatbot = () => {
  const queryClient = useQueryClient();

  const  {data: selectedChatbot } = useQuery<ChatbotConfig | null>({
    queryKey: ['selected', 'chatbot'],
    queryFn: () => Promise.resolve(null),
    enabled: false,
    initialData: null,
  });

  const updateSelectedChatbot = (chatbot: ChatbotConfig | null) => {
    queryClient.setQueryData<ChatbotConfig | null>(['selected', 'chatbot'], chatbot);
  };

  return { selectedChatbot, updateSelectedChatbot };
}